// Router
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

// Store
import store from "@/store/index";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getUser.role) {
    case "administrator":
      next({ name: "AdministrationAdministratorDashboardIndex" });
      break;
    case "client":
      next({ name: "AdministrationClientDashboardIndex" });
      break;
    case "super-administrator":
      next({ name: "AdministrationSuperAdministratorDashboardIndex" });
      break;
    default:
      store.dispatch("setLogout").then((): void => {
        next({ name: "HomeIndex" });
      });
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    next();
  } else if (to.meta.protectedRoute && store.getters.getAuthentication.token === "") {
    next({ name: "HomeIndex" });
  } else if (!to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-change-password-index" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/dashboard",
    name: "AdministrationAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-dashboard-index" */ "@/views/administration/administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/users",
    name: "AdministrationAdministratorUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-users-index" */ "@/views/administration/administrator/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/channels",
    name: "AdministrationAdministratorChannelsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-channels-index" */ "@/views/administration/administrator/channels/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/channels/:channel_id/view",
    name: "AdministrationAdministratorChannelsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-channels-view-index" */ "@/views/administration/administrator/channels/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/channels/:channel_id/channel-owners",
    name: "AdministrationAdministratorChannelsChannelOwnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-channels-channel-owners-index" */ "@/views/administration/administrator/channels/channel-owners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/channels/:channel_id/videos",
    name: "AdministrationAdministratorChannelsVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-channels-videos-index" */ "@/views/administration/administrator/channels/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/videos",
    name: "AdministrationAdministratorVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-videos-index" */ "@/views/administration/administrator/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/videos/:video_id/view",
    name: "AdministrationAdministratorVideosViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-videos-view-index" */ "@/views/administration/administrator/videos/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/categories",
    name: "AdministrationAdministratorCategoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-categories-index" */ "@/views/administration/administrator/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/tags",
    name: "AdministrationAdministratorTagsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-tags-index" */ "@/views/administration/administrator/tags/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/pages",
    name: "AdministrationAdministratorPagesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-pages-index" */ "@/views/administration/administrator/pages/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/pages/:page_id/view",
    name: "AdministrationAdministratorPagesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-pages-view-index" */ "@/views/administration/administrator/pages/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/pages/:page_id/widget/:widget_id",
    name: "AdministrationAdministratorPagesWidgetIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-pages-widget-index" */ "@/views/administration/administrator/pages/widget/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/settings/algorithms",
    name: "AdministrationAdministratorSettingsAlgorithmsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-settings-algorithms-index" */ "@/views/administration/administrator/settings/algorithms/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/settings/functionalities",
    name: "AdministrationAdministratorSettingsFunctionalitiesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-settings-functionalities-index" */ "@/views/administration/administrator/settings/functionalities/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/settings/groups",
    name: "AdministrationAdministratorSettingsGroupsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-settings-groups-index" */ "@/views/administration/administrator/settings/groups/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/settings/groups/:group_id/view",
    name: "AdministrationAdministratorSettingsGroupsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-settings-groups-view-index" */ "@/views/administration/administrator/settings/groups/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/client/dashboard",
    name: "AdministrationClientDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-client-dashboard-index" */ "@/views/administration/client/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/client/channels",
    name: "AdministrationClientChannelsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-client-channels-index" */ "@/views/administration/client/channels/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/client/channels/:channel_id/view",
    name: "AdministrationClientChannelsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-client-channels-view-index" */ "@/views/administration/client/channels/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/client/channels/:channel_id/videos",
    name: "AdministrationClientChannelsVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-client-channels-videos-index" */ "@/views/administration/client/channels/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/client/videos/:video_id/view",
    name: "AdministrationClientVideosViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-client-videos-view-index" */ "@/views/administration/client/videos/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/dashboard",
    name: "AdministrationSuperAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-dashboard-index" */ "@/views/administration/super-administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/users",
    name: "AdministrationSuperAdministratorUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-users-index" */ "@/views/administration/super-administrator/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/channels",
    name: "AdministrationSuperAdministratorChannelsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-channels-index" */ "@/views/administration/super-administrator/channels/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/channels/:channel_id/view",
    name: "AdministrationSuperAdministratorChannelsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-channels-view-index" */ "@/views/administration/super-administrator/channels/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/channels/:channel_id/channel-owners",
    name: "AdministrationSuperAdministratorChannelsChannelOwnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-channels-channel-owners-index" */ "@/views/administration/super-administrator/channels/channel-owners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/channels/:channel_id/videos",
    name: "AdministrationSuperAdministratorChannelsVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-channels-videos-index" */ "@/views/administration/super-administrator/channels/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/videos",
    name: "AdministrationSuperAdministratorVideosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-videos-index" */ "@/views/administration/super-administrator/videos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/videos/:video_id/view",
    name: "AdministrationSuperAdministratorVideosViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-videos-view-index" */ "@/views/administration/super-administrator/videos/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/categories",
    name: "AdministrationSuperAdministratorCategoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-categories-index" */ "@/views/administration/super-administrator/categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/tags",
    name: "AdministrationSuperAdministratorTagsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-tags-index" */ "@/views/administration/super-administrator/tags/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/pages",
    name: "AdministrationSuperAdministratorPagesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-pages-index" */ "@/views/administration/super-administrator/pages/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/pages/:page_id/view",
    name: "AdministrationSuperAdministratorPagesViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-pages-view-index" */ "@/views/administration/super-administrator/pages/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/pages/:page_id/widget/:widget_id",
    name: "AdministrationSuperAdministratorPagesWidgetIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-pages-widget-index" */ "@/views/administration/super-administrator/pages/widget/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/algorithms",
    name: "AdministrationSuperAdministratorSettingsAlgorithmsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-algorithms-index" */ "@/views/administration/super-administrator/settings/algorithms/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/functionalities",
    name: "AdministrationSuperAdministratorSettingsFunctionalitiesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-functionalities-index" */ "@/views/administration/super-administrator/settings/functionalities/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/groups",
    name: "AdministrationSuperAdministratorSettingsGroupsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-groups-index" */ "@/views/administration/super-administrator/settings/groups/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/groups/:group_id/view",
    name: "AdministrationSuperAdministratorSettingsGroupsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-groups-view-index" */ "@/views/administration/super-administrator/settings/groups/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/logs/exception-logs",
    name: "AdministrationSuperAdministratorLogsExceptionLogsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-logs-exception-logs-index" */ "@/views/administration/super-administrator/logs/exception-logs/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/logs/activity-logs",
    name: "AdministrationSuperAdministratorLogsActivityLogsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-logs-activity-logs-index" */ "@/views/administration/super-administrator/logs/activity-logs/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
