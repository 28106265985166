// Store
import store from "@/store";

// Translations
import translations from "@/translations";

const getTranslation = (translationList: Array<string>): any => {
  const translationsListObject: any = {};
  translationList.forEach((translation: string) => {
    const translationItem = translations.find((item) => {
      return item[translation];
    });
    translationsListObject[translation] = translationItem[translation][store.getters.getUser.locale];
  });
  return translationsListObject;
};

export { getTranslation };
